import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Home.css';

function Record() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>記録しておくところ</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">*/}
        {/*  <IonToolbar>*/}
        {/*    <IonTitle size="large">Blank</IonTitle>*/}
        {/*  </IonToolbar>*/}
        {/*</IonHeader>*/}
        まだ作ってないからあきらめて！
        <br />
        気が向いたときに少しづつやります。
      </IonContent>
    </IonPage>
  )
}

export default Record;