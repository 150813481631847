import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonInput} from "@ionic/react";
import './AnswerItem.css';
import {useRecoilState} from "recoil";
import {problemsAtom} from "../atoms/problems";
import {useState} from "react";

interface StringKeyObject {
  [key: string]: any
}

export default function AnswerItem(data: StringKeyObject) {
  let item = data["data"]
  const [problemItems, setProblemItems] = useRecoilState(problemsAtom)
  const [problemArray, setProblemArray] = useState([{"id": "1"}, {"id": "2"}, {"id": "3"}, {"id": "4"}, {"id": "5"}])//{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}
  const [color, setColor] = useState("")

  function saveTitle() {
    const input = document.getElementById('titleInput')
    //@ts-ignore
    const newTitle = input.value
    setProblemItems(oldArray => {
      let newArray = oldArray.map(function (v) {
        if (v.data.id === item["id"]) {
          return {
            data: {
              id: item,
              title: newTitle,
              "1": item["1"],
              "2": item["2"],
              "3": item["3"],
              "4": item["4"],
              "5": item["5"],
              "6": item["6"],
              "7": item["7"],
              "8": item["8"],
              "9": item["9"],
              "10": item["10"]
            }
          }
        } else {
          return v
        }
      })
      return newArray
    })
  }

  function addChoice() {
    const count = problemArray.length + 1
    if (count < 11) {
      const newdic = {"id": String(count)}
      setProblemArray(problemArray.concat([newdic]))
    }
  }

  function correct() {
    setColor("success")
  }

  function incorrect() {
    setColor("danger")
  }

  function reset() {
    setColor("")
  }

  return (
    <IonCard color={color}>
      <IonCardHeader>
        <IonCardTitle>{item["title"]}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div>
          <IonInput id="titleInput" value="タイトルを付けたい場合はここに入力"></IonInput>
          <IonButton shape="round" onClick={() => {
            saveTitle()
          }}>タイトルを保存</IonButton>
        </div>
        <div className="flexContainer">
          {problemArray.map(({id}) => (
            <div>
              <div className="choiceContainer">
                <div className="choiceTitle">{id}</div>
                <div>😀<IonCheckbox color="success"/></div>
                <div>🤪<IonCheckbox color="danger"/></div>
                <div>🤔<IonCheckbox color="secondary"/></div>
                <div>🙄<IonCheckbox color="warning"/></div>
              </div>
            </div>
          ))}
        </div>
        <div className="flexContainer">
          <IonButton shape="round" color="success" onClick={() => {
            correct()
          }}>あってた！</IonButton>
          <IonButton shape="round" color="danger" onClick={() => {
            incorrect()
          }}>まちがえてた！</IonButton>
          <IonButton shape="round" color="warning" onClick={() => {
            reset()
          }}>採点取り消し</IonButton>
          <IonButton shape="round" color="secondary" onClick={() => {
            addChoice()
          }}>選択肢を追加！</IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  )
}