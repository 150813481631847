import {IonContent, IonHeader, IonItem, IonList, IonMenu, IonTitle, IonToolbar} from "@ionic/react";

export default function Menu() {
  return (
    <IonMenu side="start" contentId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>めにゅー</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            あっち
          </IonItem>
          <IonItem>
            こっち
          </IonItem>
          <IonItem>
            ぼっち
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  )
}