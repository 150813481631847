import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Home.css';

function HowToUse() {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>使い方</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        多分習うより慣れたほうが早い
        <br /><br />
        アイコンのイメージ
        <br />
        😀←あってる選択肢
        <br />
        🤪←まちがえてる選択肢
        <br />
        🤔←検討中...たぶんあってる？
        <br />
        🙄←検討中...たぶんまちがえてる？
        <br />
        <br />
        選択肢は10個まで増やせます。
        <br />
        <br />
        解いた問題を記録する機能は開発中です・・・
        <br />
        <br />
        めにゅーなんてなかった！いいね！
      </IonContent>
    </IonPage>
  )
}

export default HowToUse;