import {atom} from "recoil";

const problemsAtom = atom({
  key: "problemItems",
  default: <Array<any>>[{
    data: {
      id: 0,
      title: "問題！",
      "1": [false, false, false, false],
      "2": [false, false, false, false],
      "3": [false, false, false, false],
      "4": [false, false, false, false],
      "5": [false, false, false, false],
      "6": [false, false, false, false],
      "7": [false, false, false, false],
      "8": [false, false, false, false],
      "9": [false, false, false, false],
      "10": [false, false, false, false]
    }
  }],
})

export {
  problemsAtom
}