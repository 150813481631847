import InfiniteScroll from "react-infinite-scroll-component";
import {useRecoilState} from "recoil";
import {problemsAtom} from "../atoms/problems";
import {IonButton} from "@ionic/react";
import AnswerItem from "./AnswerItem";

interface StringKeyObject {
  [key: string]: any
}

export default function AnswerColumn() {
  const [problemItems, setProblemItems] = useRecoilState(problemsAtom)

  function readMoreTimeLine() {

  }

  function nextMoreTimeLine() {

  }

  function addItem() {
    const items = problemItems.length
    const newId = problemItems[items - 1]["data"]["id"] + 1
    const data: StringKeyObject = {
      id: newId,
      title: "問題！",
      "1": [false, false, false, false],
      "2": [false, false, false, false],
      "3": [false, false, false, false],
      "4": [false, false, false, false],
      "5": [false, false, false, false],
      "6": [false, false, false, false],
      "7": [false, false, false, false],
      "8": [false, false, false, false],
      "9": [false, false, false, false],
      "10": [false, false, false, false]
    }
    const newObject = {data: data}
    setProblemItems(problemItems.concat([{data}]))
  }

  return (
    <div id="scrollableDiv">
      <InfiniteScroll
        dataLength={problemItems.length}
        next={readMoreTimeLine}
        hasMore={false}
        loader={
          <div>ボタンを押してカラムを追加！</div>
        }
        endMessage={
          <IonButton expand="block" onClick={() => {
            addItem()
          }}>新しい欄を追加</IonButton>
        }
        scrollableTarget="scrollableDiv"
        pullDownToRefresh={false}
      >
        {problemItems.map(({data}) => (
          <div>
            <AnswerItem data={data}/>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}