import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Menu from "./components/Menu";
import {square, triangle, atCircle} from "ionicons/icons";
import Record from "./pages/Record";
import HowToUse from "./pages/HowToUse";

function App() {
  return (
    <IonApp>
      <IonReactRouter>
        <Menu/>
        <IonTabs>
          <IonRouterOutlet id="main">
            <Route exact path="/howtouse">
              <HowToUse/>
            </Route>
            <Route exact path="/home">
              <Home/>
            </Route>
            <Route exact path="/record">
              <Record/>
            </Route>
            <Route exact path="/">
              <Redirect to="/home"/>
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={triangle}/>
              <IonLabel>問題解くところ</IonLabel>
            </IonTabButton>
            <IonTabButton tab="record" href="/record">
              <IonIcon icon={square}/>
              <IonLabel>記録しておくところ</IonLabel>
            </IonTabButton>
            <IonTabButton tab="howtouse" href="/howtouse">
              <IonIcon icon={atCircle}/>
              <IonLabel>使い方</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  )
}

export default App;